import './Dialog.scss'

import {CSSProperties} from "react"
import {BsXCircle} from "react-icons/bs"

import {CloseDialogPanel} from "../../utils/helpers"
import {Button} from "../Button/Button"

type DialogProps = {
    id?:string
    title:string
    width?:number | string | undefined
    height?:number | string | undefined
    children?:any
    submitLabel?:string
    submitIcon?:string
    submitDisabled?:boolean
    cancelLabel?:string
    cancelIcon?:string
    submitMethod?:Function
    cancelMethod?:Function
    closeColor?:string
}

export const Dialog = ({children, title, width, height, id, cancelMethod, cancelLabel, cancelIcon, submitLabel, submitIcon, submitMethod,submitDisabled}:DialogProps) => {

    const styles:CSSProperties = {}
    const footerStyles:CSSProperties = {}

    const handleClosing = (e:any) => {
        if (cancelMethod !== undefined) {
            if (e.data !== 'through-submit') {
                e.data = 'through-cancel'
            }
            cancelMethod(e)
        }
        CloseDialogPanel(e)
    }
    
    footerStyles.justifyContent = 'flex-end'

    if (cancelLabel && submitLabel) {
        footerStyles.justifyContent = 'space-between'
    }

    if (width !== undefined) styles.width = width
    if (height !== undefined) styles.height = height


    const handleSubmit = (e:any) => {
        if (submitMethod !== undefined) {
            e.data = 'through-submit'
            submitMethod(e).then((msg:any) => {
                handleClosing(e)
            }).catch((err:any) => {
            })
        }
    }

    return (
        <>
            <div id={id} className={`dialog`}>
                <div style={styles}  className={`dialog-wrapper`}>
                    <div className={`dialog-header`}>
                        {title}
                        <div onClick={handleClosing} className={`dialog-header-exit`}><BsXCircle style={{pointerEvents:'none'}}  /></div>
                    </div>
                    <div className={`dialog-content`}>
                        {children}
                    </div>
                    <div className={`dialog-footer`} style={footerStyles}>
                        {/*when submitLabel is not set the cancel button should be normal (as in not outlined)*/}
                        {(cancelLabel ? <Button outlined={submitLabel!==undefined} type={`button`} label={cancelLabel} handleClick={handleClosing} /> : null)}
                        {(submitLabel ? <Button label={submitLabel} handleClick={handleSubmit} disabled={submitDisabled} /> : null)}
                    </div>
                </div>
            </div>
        </>
    )
}
