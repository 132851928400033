let dialogOpenCount = 0

export const ToggleDialog = (data:string,onOpen?:Function) => {
    const dialogToOpen = document.getElementById(data) as HTMLDivElement
    const dialogOverlay = document.getElementById('dialog-overlay') as HTMLDivElement

    if (!dialogOverlay.classList.contains('show')) {
        dialogOverlay.classList.toggle('show');
    }

    if(!dialogToOpen.classList.contains('show')) {
        if (onOpen !== undefined) {
            onOpen()
        }
    }
    dialogToOpen.classList.toggle('show');
    dialogOpenCount++
}

export const CloseDialogPanel = (e:any) => {
    if (dialogOpenCount === 1) {
        (document.getElementById('dialog-overlay') as HTMLDivElement).classList.toggle('show');
    }

    if (e.target.type === 'button') {
        (document.getElementById(e.target.offsetParent.id) as HTMLDivElement).classList.toggle('show');
    }

    dialogOpenCount--;
}