import React, {useContext} from "react";
import {UserContext} from "../context/user.context";
import Login from "../components/Login/Login";
import useToken from "../hooks/useToken";
import TopBar from "../components/TopBar/TopBar";
import {Outlet, useLocation} from "react-router-dom";
import Bookmarks from "./Bookmarks/Bookmarks";

const Home = ({domainSuccess}:{domainSuccess:boolean}) => {

    const { userData } = useContext(UserContext)
    const { setToken } = useToken()
    const location = useLocation()

    return (
        <>
            { domainSuccess ?
                (userData && userData.data && userData.data.Id > 0 ?
                    <div className="App">
                        <TopBar/>
                        <div className={`app-section`}>
                        { location.pathname === '/'
                        ?
                            <Bookmarks />
                        :
                            <Outlet />
                        }
                        </div>
                    </div>
                    :
                    <>
                        <Login setToken={setToken} />
                    </>
                )
            :
            <div>
                What?!
            </div>
            }
            <div id={`dialog-overlay`}/>
        </>
    )
}

export default Home