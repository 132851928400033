import './Login.scss'
import useUserActions from "../../actions/user.actions";
import {useContext, useState} from "react";
import {UserContext} from "../../context/context";
import PropTypes from "prop-types";

// @ts-ignore
const Login = ({ setToken }) => {

    const { setUserData } = useContext(UserContext)
    const [username, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const userActions = useUserActions()

    const handleSubmit = async (e:any) => {
        e.preventDefault();
        const token = await userActions.loginUser(username, password);
        if (token !== undefined) {
            setToken("done");
            setTimeout(() => {
                userActions.getMe().then((res) => {
                    setUserData({data:res})
                })
                // window.location.reload();
            }, 100);
            return
        }
    }

    return (
        <div className={`login-wrapper`}>
            <div className={`login`}>
                <div className={`form`}>
                    <div className={`logo`}>
                        <img src="/images/logo.png" alt={`logo`}/>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className={`row`}>
                            <label>Username</label>
                            <input type={"text"} onChange={e => setUserName((e.target as HTMLInputElement).value)} placeholder={`enter username`} />
                        </div>
                        <div className={`row`}>
                            <label>Password</label>
                            <input type={"password"} onChange={e => setPassword((e.target as HTMLInputElement).value)} placeholder={`enter password`} />
                        </div>
                        <div className={`row`}>
                            <button type={`submit`}>Login</button>
                        </div>
                    </form>
                </div>
                <div className={`notice`}>
                    Residia v1.1 &copy; 2023
                </div>
            </div>
        </div>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};

export default Login;