import './TopBar.scss'
import {useNavigate} from "react-router-dom";
import {useContext, useEffect} from "react";
import {DomainContext, UserContext} from "../../context/context";
import useUserActions from "../../actions/user.actions";
const TopBar = () => {

    const { domainData } = useContext(DomainContext)
    const { userData } = useContext(UserContext)
    const userAction = useUserActions()
    const navigate = useNavigate()

    useEffect(() => {
        window.setInterval(() => {
            updateClock();
        }, 1000)

        const updateClock = () => {
            let clockDiv = document.getElementById('clock');
            clockDiv!.innerHTML = (new Date()).toTimeString().substring(0,5)
        }

        updateClock();
        
    }, [])

    const logoff = () => {
        userAction.logoutUser().then(() => {
            navigate('/')
            window.location.reload()
        })
    }

    return (
        <div className={`Top`}>
            <div className="Logo">
                <img src="/images/logo.png" style={{width:64}} alt={`logo`}/>
                { domainData.data.state === 'local' ?
                    <div className="title"><span style={{color:'#f26d00'}}>In</span><span style={{color:'#0095f7'}}>tranet</span></div> : null }
            </div>
            <div className="Welcome">
                Welcome,&nbsp;<div style={{minWidth:25}}>{ userData.data.Firstname } <button onClick={logoff}>Logoff</button> </div>
            </div>
            <div className="ClockWrapper">
                <div id="clock" className="Clock">
                </div>
            </div>
        </div>
    )
}

export default TopBar