import React, {useEffect, useState} from 'react';
import './App.scss';
import {DOMAIN_DATA, DomainContext} from "../context/domain.context";
import {USER_DATA, UserContext} from "../context/user.context";
import useFetchWrapper from "../utils/fetch-wrapper";
import useUserActions from "../actions/user.actions";
import {createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider} from "react-router-dom";
import Home from "./Home";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

const App = () => {

    const [domainData, setDomainData] = useState({data: DOMAIN_DATA})
    const [userData, setUserData] = useState({data: USER_DATA})
    const [domainSuccess, setDomainSuccess] = useState(false)

    const userActions = useUserActions()
    const fetchWrapper = useFetchWrapper()

    useEffect(() => {
        userActions.getMe().then((res) => {
            if (res===undefined) {

            }
            setUserData({data: res});
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchWrapper.get('/domain-context')
            .then(response => {
                if (response) {
                    return response
                } else {
                    throw new Error("domain context status " + response.status)
                }
            })
            .then(json => {
                setDomainData({data: json})
                if(json) {
                    setDomainSuccess(true)
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <DomainContext.Provider value={{domainData: domainData, setDomainData: setDomainData}}>
                <UserContext.Provider value={{userData: userData, setUserData: setUserData}}>
                    <RouterProvider router={createBrowserRouter(createRoutesFromElements(
                        <Route element={<Outlet />}>
                            <Route path={`/`} element={<Home domainSuccess={domainSuccess} />} />
                        </Route>))}
                    />
                </UserContext.Provider>
            </DomainContext.Provider>
        </>
    );
}

export default App;
