import {Dialog} from "../../../components/Dialog/Dialog";
import {useState} from "react";
import useFetchWrapper from "../../../utils/fetch-wrapper";
import useBookmarkActions from "../../../actions/bookmark.actions";

type createNewBookmarkDialogProps = {
    onClose?: Function
    bookmarkGroupId: number
    bookmarkGroupName: string
}

type newBookmarkProps = {
    GroupId: number
    Name: string
    Description: string
    Url: string
    Favicon: string
}

const NEW_BOOKMARK_INITIAL:newBookmarkProps = { GroupId: 0, Name: "", Description: "", Url: "", Favicon: ""}

const CreateNewBookmarkDialog = ({onClose, bookmarkGroupId, bookmarkGroupName}:createNewBookmarkDialogProps) => {

    const [newBookmark, setNewBookmark] = useState<newBookmarkProps>(NEW_BOOKMARK_INITIAL)

    const [url, setUrl] = useState<string>('')
    const [faviconData, setFaviconData] = useState<string | null>(null)
    const fetchWrapper = useFetchWrapper()
    const bookmarkActions = useBookmarkActions()

    const storeNewBookmark = () => {

        return new Promise(async(resolve, reject) => {

            if (newBookmark.Name.length < 2) {
                reject("Bookmark name needs at least 2 characters")
                return false
            }

            bookmarkActions.addNewBookmark(newBookmark).then(() => {
                resolve(`new bookmark ${newBookmark.Name} has been created`)
                window.location.reload()
            })
        })
    }

    const updateNewBookmark = (e:any) => {
        const {name, value} = e.target

        setNewBookmark(prevState => ({
            ...prevState,
            GroupId: bookmarkGroupId
        }))

        if (name === "Url"){
            setUrl(value)
        }

        setNewBookmark(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const fetchFavicon = () => {

        let placeholder = document.getElementById('favicon-placeholder');
        (placeholder as HTMLDivElement).innerHTML = "Loading favicon"

        // Parse the user's input URL using the URL object
        const parsedUrl = new URL(url);

        // Extract the domain with protocol from the parsed URL
        const domainWithProtocol = `${parsedUrl.protocol}//${parsedUrl.host}`;

        fetchWrapper.get('/favicon/' + btoa(domainWithProtocol)).then(response => {

            // Create a new Image element from the fetched favicon data
            const img = new Image();
            img.src = response.result;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = 32;
                canvas.height = 32;
                const ctx = canvas.getContext('2d');

                // Draw the scaled-down 32x32 image on the canvas
                ctx!.drawImage(img, 0, 0, 32, 32);

                // Convert the drawn image on the canvas into a new base64-encoded string
                const newFavicon32x32Data = canvas.toDataURL('image/png');
                setFaviconData(newFavicon32x32Data);

                setNewBookmark(prevState => ({
                    ...prevState,
                    Favicon: newFavicon32x32Data
                }))
            };
        })
    }

    return (
        <>
            <Dialog
                id={`create-new-bookmark-${bookmarkGroupName}`}
                title={`Create a new bookmark in ${bookmarkGroupName}`}
                cancelLabel={`Cancel`}
                cancelMethod={onClose}
                submitLabel={`Create`}
                submitMethod={storeNewBookmark}
            >
                <div className={`form`}>
                    <div className={`row`} style={{marginBottom:-10}}>
                        <label>Url</label>
                    </div>
                    <div className={`row`} style={{display:'flex',flexDirection:'row'}}>
                        <div style={{margin:5,marginLeft:0}}>
                            { faviconData === null ?
                                <div style={{width:32,height:32,border:'1px solid #999',textAlign:'center',fontSize:6}} id={`favicon-placeholder`}></div>
                            :
                                <img style={{width:32}} src={faviconData!} alt="Website Favicon" />
                            }
                        </div>
                        <div style={{display:'flex',flexGrow:1}}>
                            <input onBlur={fetchFavicon} style={{width:'100%'}} type={`text`} name={`Url`} value={newBookmark.Url} onChange={updateNewBookmark} />
                        </div>
                    </div>
                    <div className={`row`}>
                        <label>Name</label>
                        <input type={`text`} name={`Name`} value={newBookmark.Name} onChange={updateNewBookmark}/>
                    </div>
                    <div className={`row`}>
                        <label>Description</label>
                        <input type={`text`} name={`Description`} value={newBookmark.Description} onChange={updateNewBookmark}/>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default CreateNewBookmarkDialog