import useFetchWrapper from "../utils/fetch-wrapper";

const useBookmarkActions = () => {

    const fetchWrapper = useFetchWrapper()

    const getAllBookmarks = () : any => {
        return new Promise((resolve, reject) => {
            fetchWrapper.get('/bookmark').then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err);
            })
        })
    }

    const addNewBookmark = (bookmark: any) => {
        return fetchWrapper.post(`/bookmark`, bookmark)
    }

    return {
        getAllBookmarks,
        addNewBookmark
    }
}

export default useBookmarkActions