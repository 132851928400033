
const useFetchWrapper = () => {

    const Timeout = (time:number) => {
        const controller = new AbortController()
        setTimeout(() => controller.abort(), time * 1000)
        return controller
    }

    return {
        get: request('GET'),
        getSpecial: request('GET', true),
        post: request('POST'),
        postSpecial: request('POST', true),
        put: request('PUT'),
        putSpecial: request('PUT', true),
        patch: request('PATCH'),
        patchSpecial: request('PATCH', true),
        delete: request('DELETE'),
        deleteSpecial: request('DELETE', true)
    }

    function request(method:string, special:boolean=false) {
        return (path:string, body?:string|object|null, asForm?:boolean) => {
            const requestOptions:any = {
                method,
                credentials: 'include',
                headers:{},
                signal: Timeout(10).signal
            };
            if (body) {
                if (!asForm) {
                    requestOptions.headers['Content-Type'] = 'application/json';
                    requestOptions.headers['Content-Length'] = JSON.stringify(body).length;
                    requestOptions.body = JSON.stringify(body);
                } else {
                    requestOptions.headers['Content-Type'] = 'application/x-www-form-urlencoded';
                    // @ts-ignore
                    requestOptions.body = new URLSearchParams(body)
                }
            }

            let baseUrl = 'https://home.api.mixxy.nl/api';

            if (!special) {
                return fetch(baseUrl + path, requestOptions)
                    .then(handleResponse)
                    .catch(err => console.error(err))
            } else {
                return fetch(baseUrl + path, requestOptions)
            }
        }
    }

    function handleResponse(response:Response) {
        return response.text().then(text => {

            const data = text && JSON.parse(text);

            if (!response.ok) {
                if ([401, 403].includes(response.status) && localStorage.getItem('token')) {
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    localStorage.removeItem('token');
                    window.location.href = '/';
                }

                let error = data.error;
                if (data.error) {
                    error = {errorText: response.statusText, reportedBy: 'internal', additionalInfo:data}
                }

                if (error === undefined) error = {errorText: 'no error presented', reportedBy: 'internal', additionalInfo:data}

                return Promise.reject(response);
            }

            return data;
        }).catch(err => console.error(err));
    }
}

export default useFetchWrapper