import { useState } from 'react'

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('token')
        //const userToken = JSON.parse(tokenString!) ?? tokenString
        return tokenString//?.token
    };

    const [token, setToken] = useState(getToken())

    const saveToken = (userToken:any) => {
        if (userToken !== undefined) {
            localStorage.setItem('token', JSON.stringify(userToken))
            setToken(userToken.token)
        }
    }

    return {
        setToken: saveToken,
        token
    }
}