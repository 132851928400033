import './Button.scss'
import {CSSProperties, MouseEventHandler, useEffect, useState} from "react";

type ButtonProps = {
    id?:string
    children?: any
    color?: "accent" | "blue" | "red"
    type?: "button" | "submit" | "reset" | undefined
    iconPos?: "left" | "right" | undefined
    label?: string
    handleClick?: MouseEventHandler<HTMLButtonElement>
    tooltip?: string
    className?: string
    style?: CSSProperties
    disabled?: boolean
    asLink?: boolean
    raised?: boolean
    outlined?: boolean
    small?: boolean
}

export const Button = ({ id, className, type, label, handleClick, tooltip, color, style, disabled, asLink, raised, outlined, small}:ButtonProps) => {

    const [btnContent, setBtnContent] = useState()

    let styleString:CSSProperties = {...style};

    if (className === undefined) {
        className = ''
    }
    if (small) {
        className += " small "
    }

    const processClick = (e:any) => {
        if (handleClick !== undefined) {

            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur()
            }
            
            handleClick(e)

        }
    }

    useEffect(() => {
        if (label !== undefined) {
            // @ts-ignore
            setBtnContent(label)
        }
    }, [setBtnContent]);

    return (

        <button
            id={id}
            type={type}
            className={
                !disabled
                    ? `button ${color?`c-`+color:``} ${raised?'raised':''} ${outlined?'outlined':''} ${className?className:``} ${asLink?`as-link`:``}`
                    : `button ${color?`c-`+color:``} ${outlined?'outlined':''} Disabled ${className?className:``} ${asLink?`as-link`:``}`
            }
            data-tooltip={tooltip}
            onClick={!disabled ? processClick : ()=>{}}
            style={styleString}>
            {btnContent}
        </button>

    )
}