import useFetchWrapper from "../utils/fetch-wrapper";

const useUserActions = () => {

    const fetchWrapper = useFetchWrapper()

    function loginUser(username:string, password:string, returnPath?:string|null) {

        return fetchWrapper.post(`/auth/login`, { Username: username, Password: password }, false)
            .catch(err => {
                console.log(err);
                throw new Error("Login Error " + err.toString())
            })
    }

    function logoutUser() {
        return new Promise(resolve => {
            fetchWrapper.get('/auth/logout').then((r) => {
                localStorage.removeItem('token')
            })
            resolve(true)
        })
    }

    async function getMe() {
        try {
            return await fetchWrapper.get(`/user/me`);
        } catch (err:any) {
            throw new Error("Profile Error " + err.toString());
        }
    }
    
    return {
        loginUser,
        logoutUser,
        getMe,
    }
}

export default useUserActions
