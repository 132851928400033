import './Bookmarks.scss'
import {Fragment, useEffect, useState} from "react";
import useBookmarkActions from "../../actions/bookmark.actions";
import {BookmarkData} from "../../utils/data-types";
import CreateNewBookmarkDialog from "./dialogs/CreateNewBookmarkDialog";
import {ToggleDialog} from "../../utils/helpers";
import FetchWrapper from "../../utils/fetch-wrapper";
import EditBookmarkDialog from "./dialogs/EditBookmarkDialog";

const Bookmarks = () => {

    const useBookmarks = useBookmarkActions();
    const [bookmarks, setBookmarks] = useState<Array<BookmarkData>>([]);
    const [showNewBookmarkDialog, setShowNewBookmarkDialog] = useState(false)
    const [showEditBookmarkDialog, setShowEditBookmarkDialog] = useState(false)
    const [lastSelectedGroup, setLastSelectedGroup] = useState("")
    const [editMode, setEditMode] = useState(false)

    const useFetchWrapper = FetchWrapper()

    useEffect(() => {
        useBookmarks.getAllBookmarks().then((bookmarks:Array<BookmarkData>) => {
            setBookmarks(bookmarks);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (showNewBookmarkDialog) {
            if (document.getElementById(`create-new-bookmark-${lastSelectedGroup}`)) {
                ToggleDialog(`create-new-bookmark-${lastSelectedGroup}`)
            }
        }

        if (showEditBookmarkDialog) {
            if (document.getElementById('edit-bookmark')) {
                ToggleDialog('edit-bookmark')
            }
        }

        return () => undefined

    }, [showNewBookmarkDialog, showEditBookmarkDialog])

    const showBookmarkDialog = (name:string) => {
        setLastSelectedGroup(name)
        setShowNewBookmarkDialog(true)
    }

    const closeNewBookmarkDialog = () => {
        setShowNewBookmarkDialog(false)
    }

    const closeEditBookmarkDialog = () => {
        setShowEditBookmarkDialog(false);
    }

    const followLink = (bookmarkId:number) => {
        if (!editMode) {
            useFetchWrapper.get(`/bookmark/follow/${bookmarkId}`).then((res) => {
                window.open(res.href, "_blank")
            })
        } else {
            setShowEditBookmarkDialog(true)
        }
    }

    return (
        <>
            <div className={`bookmarks-wrapper`}>
                <div className={`title`}>
                    Bookmarks
                </div>
                <div className={`groups`}>
                    { bookmarks.map((bookmarkGroup) => {
                        return (
                            <Fragment key={`bgd-${bookmarkGroup.Id}`} >
                            <div className={`group`}>
                                <div className={`group-title`}>
                                    :: { bookmarkGroup.Name }
                                </div>
                                <div style={{display:'flex', flexWrap:'wrap'}}>
                                    { bookmarkGroup.Bookmarks.map(bookmark => {
                                        return (
                                            <div key={`bge-${bookmark.Id}`} className={`bookmark`}>
                                                <div className={`bm-icon`} onClick={() => followLink(bookmark.Id)}>
                                                    <img src={bookmark.Favicon} alt={`favicon`} />
                                                </div>
                                                <div className={`bm-name`}>
                                                    { bookmark.Name }
                                                </div>
                                            </div>
                                        )
                                    })}
                                    { !editMode ?
                                    <div className={`bookmark add-bookmark`} onClick={() => { showBookmarkDialog(bookmarkGroup.Name)}}>
                                        <div className={`bm-icon`}><span className={`plus`}>+</span></div>
                                        <div className={`bm-name`}>Add Bookmark</div>
                                    </div> : null }
                                </div>
                            </div>
                            { showNewBookmarkDialog ? <CreateNewBookmarkDialog key={`bg-${bookmarkGroup.Id}`} onClose={closeNewBookmarkDialog} bookmarkGroupId={bookmarkGroup.Id} bookmarkGroupName={bookmarkGroup.Name}/> : null }
                            </Fragment>
                        )
                    })}
                </div>
            </div>
            { showEditBookmarkDialog ? <EditBookmarkDialog onClose={closeEditBookmarkDialog} /> : null }
    </>
    )
}

export default Bookmarks;