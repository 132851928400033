import {Dialog} from "../../../components/Dialog/Dialog";

type editBookmarkDialogProps = {
    onClose?: Function
}

const EditBookmarkDialog = ({onClose}:editBookmarkDialogProps) => {

    const storeEditBookmark = () => {

    }

    return (
        <Dialog
            id={`edit-bookmark`}
            title={`Edit bookmark`}
            cancelLabel={`Cancel`}
            cancelMethod={onClose}
            submitLabel={`Create`}
            submitMethod={storeEditBookmark}
        >
        </Dialog>
    )
}

export default EditBookmarkDialog